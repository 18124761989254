import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import Heading from './heading'
import FundraisingsArchivePreview from './fundraisings-archive-preview'

import Button from './button'
import Pagination from './pagination'

import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.section`
  display: block;
  padding: 4rem 0;
  @media (min-width: 1024px) {
    max-width: 680px;
    margin: 0 auto;
  }

`

const List = styled.ul`
  display: block;
  margin: 0 2.8rem;
`

function FundraisingsArchiveList (props) {
  const {currentPage, numPages} = props

  return (
    <Wrapper>
      <Heading title='ファンドレイジング' titleEn='Fundraisings' />
      <List>
        {props && props.nodes &&
          props.nodes.map(node => (
            <li key={node._id} >
              <FundraisingsArchivePreview {...node} isInList />
            </li>
          ))}
      </List>
      <Pagination currentPage={currentPage} numPages={numPages} slug='fundraisings'/>
    </Wrapper>
  )
}

FundraisingsArchiveList.defaultProps = {
  nodes: []
}

export default FundraisingsArchiveList
